* {
  box-sizing: border-box;
}

html {
  mozosxfontsmoothing: 'grayscale';
  webkitfontsmoothing: 'antialiased';
  width: 100%;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

strong {
  font-weight: bold;
}

img {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

#nprogress .bar {
  z-index: 2000 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
